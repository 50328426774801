import React, { useEffect } from 'react';
import { FloatingHelper, ToggleSwitch, FormField, EmptyState, CustomModalLayout, TextButton, Heading, Modal, MessageModalLayout, Text, Card, FeatureList, Box, Page, InputArea, Button, Cell, Layout, Input, WixDesignSystemProvider, Image, PageSection, Badge, MarketingLayout, AnnouncementModalLayout, Tooltip, PulseAnimation, CircularProgressBar, Notification } from '@wix/design-system';
import "@wix/design-system/styles.global.css";
import * as Icons from '@wix/wix-ui-icons-common';
import './App.css';
import { ReactTagManager } from 'react-gtm-ts';

const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}

function App() {
  ReactTagManager.init(tagManagerArgs)
  const [usage, setUsage] = React.useState([] as any);
  const [isHighlighted, setIsHighlighted] = React.useState(false);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isOpening, setIsOpening] = React.useState(false);
  const [shownSettingsModal, setShownSettingsModal] = React.useState(false);
  const [merchantId, setMerchantId] = React.useState("");
  const [accessCode, setAccessCode] = React.useState("");
  const [workingKey, setWorkingKey] = React.useState("");
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: ""
    }
  });

  const isFree = instanceData?.instance?.isFree !== false;

  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getUsage();
  }, [])

  const token = new URLSearchParams(window.location.search).get('token');
  const instance = new URLSearchParams(window.location.search).get('instance')

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=55926502-cf54-4ba2-925f-99d6ef7ecdd8&redirectUrl=https://certifiedcode.wixsite.com/switch-app/_functions/@certifiedcode/base-backend/auth`;
  }

  // if (!instance) {
  //   return null
  // }

  const BASE_URL = `https://certifiedcode.wixsite.com/switch-app/_functions`

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash
          });
        }
      })
      .catch(() => {

      })
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
        setIsUpgradeBannerOpen(data.instance.isFree !== false);
      })
      .catch(() => {

      })
  }

  function setSettings() {
    // if (isUpgraded) {
    if (!open) {
      if (merchantId === "" || accessCode === "" || workingKey === "") {
        setShownSettingsModal(true);
        return;
      }
    }
    const account = !open === true ? {
      merchantId,
      accessCode,
      workingKey
    } : {}
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        "Authorization": instance || ""
      },
      body: JSON.stringify({
        isEnabled: !open,
        ...account
      })
    })
      .then(() => {
        setShownSettingsModal(false);
        setIsOpening(false);
        setOpen(!open);
        setMerchantId("");
        setAccessCode("");
        setWorkingKey("");
      })
      .catch(() => {

      })
    // } else {
    //   setIsUpgradeModalOpen(true)
    // }
  }

  function getUsage() {
    setIsOpening(true);
    fetch(BASE_URL + "/usage", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        setUsage(data);
        setIsOpening(false);
        // setOpen(data.isEnabled);
      })
      .catch(() => {

      })
  }

  const isPremiumApp = true

  const usagePercent = Number(((usage?.items || []).length / 100).toFixed(0))

  const upgradeUrl = `https://www.wix.com/apps/upgrade/${"55926502-cf54-4ba2-925f-99d6ef7ecdd8"}?appInstanceId=${(instanceData as any)['instance']['instanceId']} `

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>
          {`Upgrade to unlock "1000 sms per month"`}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeUrl, "_blank");
          }}
        >
          Upgrade
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Page height='100vh' className={`${isUpgradeBannerOpen ? "App-header-notification" : ""}`}>
        <Page.Header
          title={<Box gap={"SP2"} alignItems="middle">
            <Heading>Switch: Seamless Navigate</Heading>
          </Box>}
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              {isPremiumApp ? <Button skin="premium" disabled={isUpgraded} prefixIcon={<Icons.PremiumFilled />} onClick={() => setIsUpgradeModalOpen(true)}>Upgrade App</Button>
                : <></>}
            </Box>
          }
        />
        <Page.Content>
          <Modal
            isOpen={isUpgradeModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsUpgradeModalOpen(false)}
          >
            <AnnouncementModalLayout
              theme="premium"
              title="Retain more customers with Switch: Seamless Navigate"
              primaryButtonText="Upgrade"
              // linkText="Learn more"
              primaryButtonOnClick={() => { window.open(upgradeUrl) }}
              // linkOnClick={() => { window.open('https://support.certifiedcode.us/en/articles/7953534-getting-started-switch-app#h_b92f8de467') }}
              onCloseButtonClick={() => setIsUpgradeModalOpen(false)}
            >
              <Text>
                Switch: Seamless Navigate is a premium app that allows you to send SMS with the current page url to user's phone.
              </Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal
            isOpen={isModalOpen}
            screen="desktop"
          ><MessageModalLayout
              primaryButtonText="Refresh"
              primaryButtonOnClick={() => window.location.reload()}
              title="Ops, something went wrong"
              content={
                <Text>Please refresh this page, or contact support.</Text>
              }
            />
          </Modal>
          <Layout>
            <Cell span={12}>
              <Card>
                <Card.Header title="Send SMS with the current page url to user's phone"></Card.Header>
                <Card.Subheader title="To setup the widget, go to Editor > Add Panel (+) > App Widgets" suffix={<TextButton size="small" onClick={() => { window.Intercom("showNewMessages", "I need help setting up Switch app.") }}>Need Help?</TextButton>} />
                <Card.Content>
                  <EmptyState
                    theme="page-no-border"
                    image={
                      <CircularProgressBar value={usagePercent} error={usagePercent > 90} skin={usagePercent < 50 ? 'success' : 'standard'} size='large' />
                    }
                    title="Everything looks good!"
                    subtitle={`You've used ${usagePercent}% of your monthly quota`}
                  >
                  </EmptyState>
                </Card.Content>
              </Card>
            </Cell>
            {/* <Cell>
              <PageSection title="Suggested for you" showDivider />
            </Cell>
            <Cell span={12}>
              <Card>
                <PulseAnimation active={isHighlighted} color="B10" borderRadius="18px" fluid={true}>
                  <MarketingLayout
                    title="Accept Online Payments"
                    description="With Certified Code Business Solutions, you can accept online payments from your customers via Wix Stores, Bookings and more."
                    actions={<Box direction="horizontal" gap="SP1">
                      <Button size="small" skin="premium" as='a' href='https://certifiedcode.in/pricing' target='_blank'>See Pricing</Button>
                      <Button size="small" skin="inverted" onClick={() => { window.Intercom("showNewMessages", "I would like to accept payments with CCAvenue on Wix Stores and other Wix apps.") }}>Talk with sales</Button>
                    </Box>}
                    size="tiny"
                    badge={<Badge size="small">ADVANCED INTEGRATION</Badge>}
                    image={
                      <Box width="100%" align="right">
                        <Image
                          width="120px"
                          src="https://www.wix-pages.com/wix-design-system-employees/generic_upgrade.svg"
                          transparent
                        />
                      </Box>
                    }
                  />
                </PulseAnimation>
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  title="Contact support"
                  description="Need help? Talk with our team to get answers to your questions."
                  actions={<Button size="small" onClick={() => { window.Intercom("showNewMessages", "I need help with CCAvenue Pay Button.") }}>Live Chat</Button>}
                  size="tiny"
                  hiddenBadge
                  alignItems="stretch"
                  image={
                    <Box width="100%" align="right">
                      <Image
                        width="120px"
                        src="https://www.wix-pages.com/wix-design-system-employees/PromotionalBookingsUpgrade.svg"
                        transparent
                      />
                    </Box>
                  }
                />
              </Card>
            </Cell> */}
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
